import { Env } from './interface';

export const env: Env = {
  baseUrl: 'https://jq62m3tc09.execute-api.ap-southeast-1.amazonaws.com/qa',
  // MtaniumEnterprisesManagement
  enterpriseBaseUrl:
    'https://8iprajyhhg.execute-api.ap-southeast-1.amazonaws.com/qa',
  // MtaniumEnterprisesSignUrl
  enterpriseRegBaseUrl:
    'https://wghb28s6y4.execute-api.ap-southeast-1.amazonaws.com/qa',
  // MtaniumEnterpriseApps
  enterpriseAppBaseUrl:
    'https://hqdzcmjc1j.execute-api.ap-southeast-1.amazonaws.com/qa',
  // MtaniumEnterprisesPolicies
  policyBaseUrl:
    'https://rxbvbmddn6.execute-api.ap-southeast-1.amazonaws.com/qa',
  deviceBaseUrl:
    'https://lpaj3n5q19.execute-api.ap-southeast-1.amazonaws.com/qa',
  apiVersion: 'api/v1',
  environment: 'beta',
  isDev: false,
  recaptchaSiteKey: '6LeBDvYUAAAAAFt0FKDBAXxaAj3DaBMvzlNhPisa',
};
